body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    background-color: #262626;
    background-attachment: fixed;
    background-image: url("https://ranking.thugz.life/static/media/Thugz-Mint-Background.8faf8f06aa0a7a01c6f5.png");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.gradient-text {
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-image: linear-gradient(#ffb750,#f7881b);
  font-family: Condensed Black Italic;
}

#root {
  margin: 20px auto 0;
}

.logo img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 15vw;
}

.lottery {text-align: center;}
.title {margin: 50px auto; font-size: 60px;}

.result {display: none;}





/* BEVEL BUTTON */

.drawing {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column-reverse;
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: scale(1.25);
  overflow: hidden;
  white-space: nowrap;
  user-select: none;
}
input {
  display: none;
}
label {
  cursor: pointer;
}
.bevel {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0 32px;
  height: 54px;
  background: #f33 linear-gradient(to bottom, #f33 50%, #d11515 50%);
  border-radius: 0px;
  box-shadow: 0px 15px 25px -10px rgba(0, 0, 0, 0.5), inset 0px 0px 0px 0px rgba(255, 51, 51, 0), inset 0px -27px 0px 0px #d11515;
  font-size: 20px;
  text-align: center;
  text-shadow: 0px 3px 5px #d11515, 0px 5px 10px #d11515, 0px 5px 10px #d11515, 0px 5px 10px #d11515, 0px 5px 10px #d11515;
  color: #fff;
  letter-spacing: 0.4px;
  overflow: hidden;
  outline: 0 !important;
  transform: scale(1.01);
  transition: all 0.2s ease-in-out;
}
.bevel:before, 
.bevel:after {
  content: " ";
  width: 27px;
  max-width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  transition: all 0.135s ease-in-out;
  transition: all 0.135s cubic-bezier(0.74, 0.16, 0.24, 0.92);
  transition-delay: 0.15s;
}
.bevel:before {
  left: 0;
  background: linear-gradient(45deg, transparent calc(200% / 3), rgba(255, 51, 51, 1) calc(200% / 3)), linear-gradient(-45deg, rgba(209, 21, 21, 1) calc(100% / 3), transparent calc(100% / 3)), linear-gradient(to right, #f33, #d11515);
}
.bevel:after {
  right: 0;
  background: linear-gradient(-45deg, transparent calc(200% / 3), rgba(255, 51, 51, 1) calc(200% / 3)), linear-gradient(45deg, rgba(209, 21, 21, 1) calc(100% / 3), transparent calc(100% / 3)), linear-gradient(to left, #f33, #d11515);
}
.bevel:hover {
  transform: scale(1.07);
  box-shadow: 0px 25px 40px -5px rgba(0, 0, 0, 0.3), inset 0px 0px 0px 0px rgba(255, 51, 51, 0), inset 0px -27px 0px 0px #d11515;
}
input:checked ~ .bevel {
  padding: 0 18.5px;
  background: rgba(255, 51, 51, 0);
  box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.3), inset 0px 0px 0px 4px #f33, inset 0px 0px 0px 0px #d11515;
  border-radius: 10px;
  font-size: 24px;
  font-weight: 700;
  text-shadow: 0px 3px 5px rgba(209, 21, 21, 0), 0px 5px 10px rgba(209, 21, 21, 0), 0px 5px 10px rgba(209, 21, 21, 0), 0px 5px 10px rgba(209, 21, 21, 0), 0px 5px 10px rgba(209, 21, 21, 0);
  color: #f33;
  transform: scale(0.7) rotate(-6deg);
  transition: all 0.25s ease-in-out, text-shadow 0s ease-in-out, box-shadow 0.25s ease-in-out;
  transition: all 0.45s cubic-bezier(0.6, -0.9, 0.39, 1.65), text-shadow 0s ease-in-out, box-shadow 0.45s cubic-bezier(0.6, -0.9, 0.39, 1.65);
  transition-delay: 0.135s;
}
input:checked ~ .bevel:before, input:checked ~ .bevel:after {
  transition-delay: 0s;
}
input:checked ~ .bevel:before {
  transform: translateX(-100%);
}
input:checked ~ .bevel:after {
  transform: translateX(100%);
}
input:checked ~ .bevel + span {
  color: rgba(255, 51, 51, 0.1875);
  transform: translateY(56px);
}








/* LETTER */

#letter {display: none; margin-top: 20px; transform: scale(1.25);}

.letter-image {
  position: absolute;
  z-index: 10;
  top: 60%;
  left: 37%;
  width: 400px;
  height: 200px;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  cursor: pointer;
}
.animated-mail {
  position: absolute;
  height: 150px;
  width: 400px;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  transition: 0.4s;
}
.animated-mail .body {
  position: absolute;
  bottom: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 100px 400px;
  border-color: transparent transparent #e95f55 transparent;
  z-index: 2;
}
.animated-mail .top-fold {
  position: absolute;
  top: 50px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 51px 201px 0;
  -webkit-transform-origin: 50% 0%;
  -webkit-transition: transform 0.4s 0.4s, z-index 0.2s 0.4s;
  -moz-transform-origin: 50% 0%;
  -moz-transition: transform 0.4s 0.4s, z-index 0.2s 0.4s;
  transform-origin: 50% 0%;
  transition: transform 0.4s 0.4s, z-index 0.2s 0.4s;
  border-color: #cf4a43 transparent transparent transparent;
  z-index: 2;
}
.animated-mail .back-fold {
  position: absolute;
  bottom: 0;
  width: 400px;
  height: 100px;
  background: #cf4a43;
  z-index: 0;
}
.animated-mail .left-fold {
  position: absolute;
  bottom: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 50px 0 50px 200px;
  border-color: transparent transparent transparent #e15349;
  z-index: 2;
}
.animated-mail .letter {
  left: 20px;
  bottom: 1px;
  position: absolute;
  width: 360px;
  height: 60px;
  background: white;
  z-index: 1;
  overflow: hidden;
  -webkit-transition: 0.4s 0.2s;
  -moz-transition: 0.4s 0.2s;
  transition: 0.4s 0.2s;
}
.animated-mail .letter .letter-border {
  height: 10px;
  width: 100%;
  background: repeating-linear-gradient(-45deg, #cb5a5e, #cb5a5e 8px, transparent 8px, transparent 18px);
}
.animated-mail .letter .letter-title {
  height: 6px;
  margin-left: 5px;
  margin-top: 5px;
  width: 40%;
  background: #cb5a5e;
}
.animated-mail .letter .letter-label {
  margin-left: 5px;
  margin-top: 15px;
  font-family: Georgia;
  font-size: 13.5px;
  text-align: left;
}
.animated-mail .letter .letter-context {
  height: 10px;
  margin-left: 5px;
  margin-top: 10px;
  width: 25%;
  font-family: Georgia;
  font-size: 13.5px;
}
.animated-mail .letter .letter-stamp {
  margin-top: -50px;
  margin-left: 320px;
  border-radius: 100%;
  height: 30px;
  width: 30px;
  background: #cb5a5e;
  opacity: 0.3;
}
.shadow {
  position: absolute;
  top: 200px;
  left: 50%;
  width: 400px;
  height: 30px;
  transition: 0.4s;
  transform: translateX(-50%);
  -webkit-transition: 0.4s;
  -webkit-transform: translateX(-50%);
  -moz-transition: 0.4s;
  -moz-transform: translateX(-50%);
  border-radius: 100%;
  background: radial-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0));
}
.letter-image:hover .animated-mail {
  transform: translateY(50px);
  -webkit-transform: translateY(50px);
  -moz-transform: translateY(50px);
}
.letter-image:hover .animated-mail .top-fold {
  transition: transform 0.4s, z-index 0.2s;
  transform: rotateX(180deg);
  -webkit-transition: transform 0.4s, z-index 0.2s;
  -webkit-transform: rotateX(180deg);
  -moz-transition: transform 0.4s, z-index 0.2s;
  -moz-transform: rotateX(180deg);
  z-index: 0;
}
.letter-image:hover .animated-mail .letter {
  height: 180px;
}
.letter-image:hover .shadow {
  width: 450px;
}
